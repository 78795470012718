//Configuring axios to add authorization header in each api calls
const { default: axios } = require("axios");
const { BASE_URL } = require("config");
import { toast } from "react-toastify";


const axiosBaseurl = BASE_URL;

const axiosInstance = axios.create({
  baseURL: axiosBaseurl,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const authUser = JSON?.parse(localStorage?.getItem("authUser"));
      const token = authUser?.token;
      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (err) {
      // handel error
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.data?.authError) {
      const overlay = document.createElement("div");
  overlay.classList.add("screen-freeze"); // Add class
  document.body.appendChild(overlay); // Add to <body>
      toast.error(
        error?.response?.data?.msg?.message ||
          error?.response?.data?.msg ||
          "Unexpected error occurred",
        { position: "top-center", autoClose: 5000, onClose: () => {
          window.location.reload(); // Reloads when toast is closed
        } }
      );

      localStorage.removeItem("authUser");
      //window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
