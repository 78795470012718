// utils/tokenCheck.js
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

const triggerLogoutFlow = () => {
  // Create screen freeze overlay

  const overlay = document.createElement("div");
  overlay.classList.add("screen-freeze");
  document.body.appendChild(overlay);

  // Show toast and reload after closing
  toast.error("Session expired. Please log in again.", {
    position: "top-center",
    autoClose: 5000,
    onClose: () => {
      window.location.reload();
    },
  });

  localStorage.removeItem("authUser");
};

export const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  } catch (error) {
    console.error("Invalid token:", error);
    return true;
  }
};

// Automatically handle expiration and redirect
export const checkTokenAndRedirect = () => {
  //Bypass token check if we're on the login page
  if (window.location.pathname === "/login") return;

  const authUser = JSON?.parse(localStorage?.getItem("authUser"));
  const token = authUser?.token;

  if (isTokenExpired(token)) {
    triggerLogoutFlow();
  } else {
    // Calculate time left and set timeout to logout precisely on expiry
    const { exp } = jwtDecode(token);
    const timeLeft = exp * 1000 - Date.now();
    console.log(timeLeft);
    setTimeout(triggerLogoutFlow, timeLeft);
  }
};
