import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BarcodeReader from "react-barcode-reader";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import QrReader from "react-qr-scanner";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { v4 as uuid } from "uuid";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { BASE_URL } from "config";
import MenuItem from "@mui/material/MenuItem";
import paginationFactory from "react-bootstrap-table2-paginator";
import MUIDataTable from "mui-datatables";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { QrScanner } from "@yudiel/react-qr-scanner";
import axiosInstance from "Axiosinstance";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import Loader from "components/Loader";
import { Col } from "reactstrap";
import Chip from "@mui/material/Chip";
import { local } from "utils/Constant";

function Dispatch() {
  const [loader, setloader] = useState(false);
  const [totalscan, settotalscan] = useState(0);
  const [dispatcherror, setdispatchrror] = useState({
    iserror: false,
    errormsg: "",
  });
  const [alldispatch, setalldispatch] = useState([]);
  const [dispatchmodal, setdispatchmodal] = useState(false);
  const [datefilter, setdatefilter] = useState({
    from: "",
    to: "",
  });
  const [searchkey, handelsearchinput] = useState("");
  const [result, setresult] = useState([]);
  const [formdata, setformdata] = useState({
    dispatch_location: "",
    dispatch_code: "",
  });
  const [protable, setprotable] = useState(false);
  const [products, setproducts] = useState([]);
  const [dispatchdetails,setdispatchdetails] = useState({})
  const [transaction,settransaction] = useState([])
  const barer = (er)=>{
  
    setdispatchrror(((prev)=>{
      return {
        iserror : true,
        errormsg : er || er?.message
      }
    }))
    console.log(er)
  }

  const handeldownload = async (id) => {
    try {
      setloader(true);

      const { data } = await axiosInstance.post(
        "/api/dispatchdownload",
        { id: id },
        {
          responseType: "blob", // Tell axios to expect binary data
        }
      );
      // Create a download link for the PDF
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = "dispatchproducts.pdf";
      link.click();
      window.URL.revokeObjectURL(url);
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error);
    }
  };
  const getdispatchLogs = async () => {
    try {
      const { data } = await axiosInstance.get("/api/getalldispatch");
      if (data?.status) {
        setresult(data?.logs);
      }
    } catch (error) {}
  };
  const handelsubmit = async () => {
    try {
      setloader(true);
      setdispatchmodal(false);
      const reqData = {
        formdata,
        dispatch_codes: alldispatch,
      };
      const { data } = await axiosInstance.post("/api/handeldispatch", reqData);
      if (data?.status) {
        getdispatchLogs();
        setloader(false);
      }
      setloader(false);
      handelreset();
    } catch (error) {
      alert(error?.response?.data?.message || "Failed")
      setloader(false);
      handelreset();
    }
  };
  const [scanner, setscanner] = useState(false);

  useEffect(() => {
    if (!searchkey.length) {
      getdispatchLogs();
    } else {
      const getsearchResult = setTimeout(() => {
        axiosInstance
          .post("/api/dispatchsearch", { key: searchkey })
          .then(({ data }) => {
            setresult(data.result);
          });
      }, 1000);
      return () => clearTimeout(getsearchResult);
    }
  }, [searchkey, datefilter]);
  const handeldate = (e) => {
    setdatefilter({ ...datefilter, [e?.target?.name]: e.target.value });
  };
  const handelreset = () => {
    setdispatchrror((prev) => {
      return {
        ...prev,
        iserror: false,
        errormsg: "",
      };
    });

    setformdata({ _id: "" });
    setdispatchmodal(false);
    setalldispatch([]);
    settotalscan(0);
  };

  const handelChange = (e) => {
    setformdata({
      ...formdata,
      [e?.target?.name]: e?.target?.value,
    });
  };
  const openscanner = () => {
    setscanner(true);
  };

  const handelview = (product,otherdetials) => {
    let p = otherdetials?.transaction_summary[0]
    const summaryArray = Object.entries(p);
    console.log(summaryArray)
    settransaction(summaryArray)
    setproducts(product);
    setdispatchdetails(otherdetials)
    setprotable(true);
  };
  const dispatchScan = async (result) => {
    console.log(result)
    try {
      
      setdispatchrror((prev) => {
        return {
          ...prev,
          iserror: false,
          errormsg: "",
        };
      });
      if (alldispatch?.includes(result)) {
        setdispatchrror((prev) => {
          return {
            ...prev,
            iserror: true,
            errormsg: `Dispatch code ${result} already scaned , please try another`,
          };
        });
      } else {
        const { data } = await axiosInstance.post("/api/checkdispatchcode", {
          dispatch_code: result,
        });
        console.log(data)

        if (data?.status) {
          setdispatchrror((prev) => {
            return {
              ...prev,
              iserror: false,
              errormsg: "",
            };
          });

          /* setformdata((prev)=>{
            return {
              ...prev,
              dispatch_code : result
            }
          })*/

          setalldispatch([...alldispatch, result]);
          settotalscan((prev) => prev + 1);
        } else {
          setdispatchrror((prev) => {
            return {
              ...prev,
              iserror: !iserror,
              errormsg: data?.message || "Dispatch code already present",
            };
          });
        }
      }
    } catch (error) {
   
      console.log(error)
      setdispatchrror((prev) => {
        return {
          ...prev,
          iserror: true,
          errormsg:  "unexpected error ocured",
        };
      });
    }
  };

  const dummyDispatch = async () => {
    try {
      setdispatchrror((prev) => {
        return {
          ...prev,
          iserror: false,
          errormsg: "",
        };
      });

      let res = "225566";
      if (alldispatch?.includes(res)) {
        setdispatchrror((prev) => {
          return {
            ...prev,
            iserror: true,
            errormsg: `Dispatch code ${res} already scaned , please try another`,
          };
        });
      } else {
        const { data } = await axiosInstance.post("/api/checkdispatchcode", {
          dispatch_code: res,
        });
        if (data?.status) {
          setdispatchrror((prev) => {
            return {
              ...prev,
              iserror: false,
              errormsg: "",
            };
          });
          setalldispatch([...alldispatch, res]);
          //console.log(alldispatch)
          settotalscan((prev) => prev + 1);
        } else {
          setdispatchrror((prev) => {
            return {
              ...prev,
              iserror: !iserror,
              errormsg: data?.message || "Dispatch code already present",
            };
          });
        }
      }

      //setdispatchmodal(false)
    } catch (error) {
      //console.log(error?.response?.data?.message)
      setdispatchrror((prev) => {
        return {
          ...prev,
          iserror: true,
          errormsg:
            error?.response?.data?.message || "Dispatch code already present",
        };
      });
    }
  };
  const sortdateApi = async () => {
    try {
      if (Object.keys(datefilter).length === 2) {
        const { data } = await axiosInstance.post(`/api/dispatchsortbydate`, {
          datefilter,
        });
        //console.log(data);
        if (data?.status) {
          setresult(data?.result);
        }
      }
    } catch (error) {}
  };
  const column1 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "dispatch_date",
      text: "Scanned On",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.dispatch_date ? (
              <a className="text-dark">
                {new Date(product.dispatch_date).toLocaleString()}
              </a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
     {
      text: "Created By", // Add this new column
      dataField: "created_by",
      sort: true,
      formatter: (cellContent, product) => (
        <>
          <h5 className="font-size-14 mb-1">
            {product.created_by ? product.created_by : "N/A"}
          </h5>
        </>
      ),
    },
    // {
    //   dataField: "uid",
    //   text: "Uid",
    //   formatter: (cell) => cell || "N/A",
    // },
    {
      dataField: "dispatch_location",
      text: "Dispatch Location",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.dispatch_location ? (
              <a className="text-dark">{product?.dispatch_location}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "transaction_id",
      text: "Transaction id",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.transaction_id ? (
              <a className="text-dark">{product?.transaction_id}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },

    {
      dataField: "dispatch_location",
      text: "View",
      formatter: (cellContent, product) => {
        return (
          <a
            onClick={() => handelview(product?.dispatch_products,product)}
            className="font-size-14 mb-1"
          >
            View Products
          </a>
        );
      },
    },
    {
      dataField: "dispatch_location",
      text: "Download",
      formatter: (cellContent, product) => {
        return (
          <a
            onClick={() => handeldownload(product?._id)}
            className="font-size-14 mb-1"
          >
            Download
          </a>
        );
      },
    },
  ];
  const column = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "tag_number",
      text: "Tagnumber",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.tag_number ? (
              <a className="text-dark">{product.tag_number}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "mac_address",
      text: "Mac address",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.mac_address ? (
              <a className="text-dark">{product.mac_address}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "product_category",
      text: "Product Category",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.product_category ? (
              <a className="text-dark">{product.product_category}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "producttype",
      text: "producttype",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.producttype ? (
              <a className="text-dark">{product.producttype}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },

    {
      dataField: "productcolor",
      text: "Product Color",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.productcolor ? (
              <a className="text-dark">{product.productcolor}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },

    {
      dataField: "subproducttype",
      text: "Embeded Hardware",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.subproducttype ? (
              <a className="text-dark">{product.subproducttype}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },

    {
      dataField: "subproductcolor",
      text: "Hardware Color",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.subproductcolor ? (
              <a className="text-dark">{product.subproductcolor}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.customer_name ? (
              <a className="text-dark">{product.customer_name}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "dispatch_code",
      text: "Dispatch Code",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.dispatch_code ? (
              <a className="text-dark">{product.dispatch_code}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
  ];
  return (
    <div className="page-content">
      {loader ? (
        <Loader />
      ) : (
        <>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div
              style={{
                marginBottom: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>Dispatch</h1>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ marginRight: "5rem" }}>
                <Button
                  onClick={() => {
                    setdispatchmodal(true);
                  }}
                  style={{ margin: "1rem" }}
                  variant="contained"
                >
                  Start New Dispatch
                </Button>
              </div>
            </div>

            <Col style={{ display: "flex" }} xl={6} sm={6}>
              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="form-control"
                name="searchkey"
                value={searchkey}
                onChange={(e) => handelsearchinput(e?.target?.value)}
              />
            </Col>

            <div style={{ marginTop: "2rem", display: "flex" }}>
              <div style={{ marginLeft: "1rem" }}>
                <TextField
                  label="Starting Date"
                  variant="outlined"
                  type="date"
                  name="from"
                  onChange={handeldate}
                  value={datefilter?.from || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: new Date().toISOString().split("T")[0],
                  }}
                />
              </div>
              <div style={{ marginLeft: "1rem" }}>
                <TextField
                  label="Ending Date"
                  variant="outlined"
                  type="date"
                  name="to"
                  onChange={handeldate}
                  value={datefilter?.to || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: new Date().toISOString().split("T")[0],
                  }}
                />
              </div>
              {datefilter?.from && datefilter?.to ? (
                <div style={{ margin: "1rem" }}>
                  <Button onClick={sortdateApi} variant="outlined">
                    Search
                  </Button>
                </div>
              ) : null}
            </div>

            <p style={{ fontWeight: "500", marginTop: "3rem" }}>
              Dispatch History
            </p>
            <div
              style={{ backgroundColor: "white", marginTop: "2rem" }}
              className="table-responsive"
            >
              <BootstrapTable
                keyField="id"
                data={result}
                columns={column1}
                pagination={paginationFactory()}
              />
            </div>
            <Dialog fullScreen open={dispatchmodal}>
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handelreset}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Data Pairing
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handelreset}>
                    Close
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent dividers={scroll === "paper"}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "600" }}></p>
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        value={formdata.dispatch_location}
                        onChange={handelChange}
                        name="dispatch_location"
                        label="Dispatch location"
                        variant="outlined"
                      />
                    </div>

                    {formdata.dispatch_location?.length ? (
                      <>
                        <div style={{ margin: "1rem" }}>
                          <Button onClick={openscanner} variant="contained">
                            {" "}
                            Scan Dispatch Code
                          </Button>
                        </div>

                        {alldispatch?.length ? (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Dispatch code</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {alldispatch.map((e, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell>{e}</TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Button
                              onClick={handelsubmit}
                              style={{ margin: "2rem", alignItems: "center" }}
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>

          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={scanner}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan Dispatchcode
            </DialogTitle>
            {dispatcherror.iserror ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                {dispatcherror.errormsg || "unexpected error ocured try again"}
              </p>
            ) : (
              <>
                <p style={{ textAlign: "center" }}>
                  Total no of scan : {totalscan}
                </p>
                <p style={{ textAlign: "center", fontWeight: "500" }}>
                  Please scan your Dispatch Barcode{" "}
                </p>
                <small style={{ textAlign: "center" }}>
                  Using your barcode reader
                </small>
              </>
            )}

            <BarcodeReader
             minLength = {3}
            onError = {barer}
              onScan={dispatchScan}
              style={{ width: "50%", marginLeft: "4rem", marginBottom: "2rem" }}
            />

            <DialogActions>
              <Button
                onClick={() => {
                  setscanner(false),
                    setdispatchrror((prev) => {
                      return {
                        ...prev,
                        iserror: false,
                        errormsg: "",
                      };
                    });
                }}
              >
                Cancel
              </Button>
              {local && <Button onClick={dummyDispatch}>Sample</Button>}
            </DialogActions>
          </Dialog>

          <Dialog fullScreen open={protable}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handelreset}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Dispatched Products
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => setprotable(false)}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <DialogContent dividers={scroll === "paper"}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div style={{ marginTop: "7rem" }}>
                    <div>
                      <h1 style={{textAlign:"center"}}>Dispatch Details</h1>
                      <div className="dispatxh_summary">
                        <p>Dispached Date : {new Date(dispatchdetails.dispatch_date).toLocaleString()}</p>
                        <p>Transaction id : {dispatchdetails?.transaction_id}</p>
                        <p>Dispatch location : {dispatchdetails?.dispatch_location}</p>
                        <p>Total Product Count : {dispatchdetails?.total_count}</p>
                   {
                    transaction?.map(([key,value],index)=>{
                      return(
                      <p key={key}>{key}: {value}</p>
                      )
                    })
                   }
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="id"
                      data={products}
                      columns={column}
                      pagination={paginationFactory()}
                    />
                  </div>

                  <DialogActions>
                    <Button onClick={() => setprotable(false)}>Cancel</Button>
                  </DialogActions>
                </Box>
              </DialogContent>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default Dispatch;
